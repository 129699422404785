
import { Component, Vue, Watch } from "vue-property-decorator";
import { Tabbar, TabbarItem } from "vant";

import { getAppType, setAppType } from "@/utils/cookies";
import { loginService } from "@/api/mine/login/index.api";

@Component({
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
})
export default class layout extends Vue {
  public tabbarActive = "/home";
  public tabber = [
    {
      title: "首页",
      iconT: require("@image/tabbar/shouyeT.png"),
      iconF: require("@image/tabbar/shouyeF.png"),
      url: "/home",
    },
    {
      title: "订单列表",
      iconT: require("@image/tabbar/RoomStateT.png"),
      iconF: require("@image/tabbar/RoomStateF.png"),
      url: "/order",
    },
    {
      title: "我的",
      iconT: require("@image/tabbar/meT.png"),
      iconF: require("@image/tabbar/meF.png"),
      url: "/mine",
    },
  ];

  async created() {
    setAppType("ningmeng");

    let {
      data: { ReturnJson },
    } = await loginService({
      Action: "GetWeChatLoginUrl",
      AppType: getAppType(),
    });
    sessionStorage.setItem('WeChatLoginUrl',JSON.parse(ReturnJson))
    
  }
}
